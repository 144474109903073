<div class="component" *ngIf="orderInformation | async as orderInformation" >
  <h2 class="font-weight-bold">{{"orderDetail.orderInformation"|cxTranslate}}</h2>
  <div class="row" >
    <div class=" component col-xl-6">
      <div class="form-group">
        <label class="label-text">{{"orderDetail.company"|cxTranslate}} </label>
        <p class="information-text" *ngIf="!orderInformation?.legalEntity?.company">-</p>
        <p class="information-text" *ngIf="orderInformation?.legalEntity?.company">{{orderInformation?.legalEntity?.company}}</p>
      </div>
      <div class="form-group">
        <label class="label-text">{{"orderDetail.requesterName"|cxTranslate}}</label>
        <div>
          <p class="information-text" *ngIf="!orderInformation?.user?.name">-</p>
          <p class="information-text"*ngIf="orderInformation?.user?.name">{{orderInformation?.user?.name}}</p>
          <p id="requesterEmail" class="information-text">
            {{orderInformation?.user?.email}}
          </p>
        </div>
      </div>
      <div class="form-group">
        <label class="label-text">{{"orderDetail.sgreSo"|cxTranslate}}</label>
        <p class="information-text" *ngIf ="!orderInformation?.sapSalesOrderNo">--</p>
        <p class="information-text" *ngIf ="orderInformation?.sapSalesOrderNo">{{orderInformation?.sapSalesOrderNo}}</p>
      </div>
      <div class="form-group">
        <label class="label-text">{{"orderDetail.orderStatus"|cxTranslate}}</label>
        <p class="information-text" *ngIf="!orderInformation?.status">-</p>
        <p class="information-text" *ngIf="orderInformation?.status">{{orderInformation?.status}}</p>
      </div>
    </div>
    <div class="component col-xl-6">
      <div class="form-group">
        <label class="label-text">{{"orderDetail.legalEntity"|cxTranslate}}</label>
        <p class="information-text" *ngIf="!orderInformation?.legalEntity?.name">-</p>
        <p class="information-text" *ngIf="orderInformation?.legalEntity?.name">{{orderInformation?.legalEntity?.name}}</p>
      </div>
      <div class="form-group">
        <label class="label-text">{{"orderDetail.approverName"|cxTranslate}}</label>
        <p class="information-text-none" *ngIf="!orderInformation.approver">-</p>
        <p class="information-text">{{orderInformation.approver?.name}}</p>
        <p id="requesterEmail" class="information-text">
          {{orderInformation?.approver?.email}}
        </p>
      </div>
      <div class="form-group">
        <label class="label-text">{{"orderDetail.companyPo"|cxTranslate}} </label>
        <p class="information-text">{{orderInformation?.customerPurchaseOrderNo}}</p>
      </div>
      <div class="form-group">
        <label class="label-text">{{"orderDetail.orderDate"|cxTranslate}}</label>
        
        <p class="information-text">{{getUpdatedDate(orderInformation?.date)}}</p>
      </div>
    </div>
  </div>
</div>
